<template>
  <div class="contactWrap">
      <a href='https://m.booking.naver.com/booking/6/bizes/1034066?theme=place&entry=pll&area=pll'>
        견적문의 및 예약하기
      </a>
      <p>
        E<br/>
        halanginterior@naver.com<br/>
        <br/>
        T<br/>
        010. 3690. 9407<br/>
        <br/>
        A<br/>
        경기도 성남시 수정구 위례광장로328, 우성위례타워 805호 <br/>
        805 Woosung Wirye Tower, 328 Wiryegwangjang-ro, Sujeong-gu, Seongnam-si, Gyeonggi-do <br/>
      </p>
    </div>
</template>

<script>
export default {
  name : 'ContactComponent',
}
</script>

<style>
.contactWrap {
  padding-top: 70px;
}

p{
  margin-top: 50px;
  margin-bottom: 50px;
  line-height: 25px;
  font-size: 0.8rem;
}

a{
  color: inherit;
}
a:hover {
    color: #136AB2
}
</style>